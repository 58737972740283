<template>
    <div class="home">
        <img src="../assets/home.png" width="100%;height:auto" alt="">
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>